import React from "react";

import { Button, Modal, Typography } from "ae-ui-components";
import { AlertTriangle } from "react-feather";

interface Props {
  cleanup: (data: boolean) => void;
  message: string;
}

const { Title } = Typography;

const Prompt: React.FC<Props> = ({ cleanup, message }) => {
  return (
    <Modal
      className="custom-prompt"
      title="Confirm"
      size="small"
      buttons={
        <>
          <Button type="default" onClick={() => cleanup(false)}>
            Cancel
          </Button>
          <Button onClick={() => cleanup(true)}>Ok</Button>
        </>
      }
      isOpen
      onClose={() => cleanup(false)}
    >
      <div className="flex w-full h-full">
        <AlertTriangle
          className="prompt-icon"
          size={40}
          color="var(--ae-orange)"
        />
        <Title level={4} className="flex">
          {message}
        </Title>
      </div>
    </Modal>
  );
};

export default Prompt;
