import React, { useCallback } from "react";

import ReactDOM from "react-dom";
import { BrowserRouter as BRouter } from "react-router-dom";

import App from "../App/App";
import { CustomPrompt } from "../common";

const Router: React.FC = () => {
  const userConfirmation = useCallback((message: string, callback: any) => {
    const node = document.getElementById("custom-prompt") as HTMLElement;

    const cleanup = (answer: boolean) => {
      ReactDOM.unmountComponentAtNode(node);
      callback(answer);
    };

    ReactDOM.render(<CustomPrompt message={message} cleanup={cleanup} />, node);
  }, []);
  return (
    <BRouter getUserConfirmation={userConfirmation}>
      <App />
    </BRouter>
  );
};

export default Router;
