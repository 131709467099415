import { Button, Input, Typography } from "ae-ui-components";
import { Form } from "antd";

import "./ForgotPassword.scss";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import AeLogo from "../../../assets/imgs/AE_Qube_color_rgb.png";
import { fetchData } from "../../LandingPage/functions/fetchUser";
import { sendEmailUrl } from "../../../helpers/RestApisHelper/RestApis";
import { ArrowLeft, CheckCircle } from "react-feather";
import ReCAPTCHA from "react-google-recaptcha";
import { getRecaptchaSiteKey } from "../../../constants";

const { Caption } = Typography;

const ForgotPassword = () => {
  const history = useHistory();
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoding] = useState(false);
  const [error, setError] = useState(false);
  const [enteredMail, setEnteredMail] = useState<string>("");
  const [forgetFlag, setForgetFlag] = useState(false);
  const siteKey = getRecaptchaSiteKey();
  const [errorMsg, setErrorMsg] = useState<string>("");
  const handleForgotPassword = async (vals: any, token: string) => {
    setLoding(true);
    if (vals.email) {
      const url = sendEmailUrl;
      const data = JSON.stringify({
        email: vals.email.trim(),
        recaptchaToken: token,
      });
      await fetchData(url, data)
        .then((val) => {
          console.log({ val });
          setEmailSent(true);
          setLoding(false);
          setError(false);
          setEnteredMail("");
        })
        .catch((err) => {
          setLoding(false);
          setError(true);
          console.log({ err });
          setErrorMsg(err?.message || "Please try again.");
        });
    }
  };

  const onCaptchaChange = (value: string) => {
    if (value) {
      setForgetFlag(false);
      handleForgotPassword(enteredMail, value);
    }
  };
  const getCaptcha = (vals: string) => {
    setForgetFlag(true);
    setEnteredMail(vals);
  };

  return (
    <div className="forgot-password-container">
      <div className="email-input-header">
        <img src={AeLogo} alt="ae-qube" />
      </div>
      <Caption bold level={1}>
        {emailSent ? (
          <div className="email-sent-forgot-password flex justify-center flex-col items-center">
            <div style={{ marginBottom: "20px" }} className="mb-10">
              <p>Email has been sent to your account.</p>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <CheckCircle size={50} color="#34C759" />
            </div>
            <div>
              <ArrowLeft
                onClick={() => history.push("/")}
                style={{ cursor: "pointer" }}
                size={35}
                color="#005ABC"
              />
            </div>
          </div>
        ) : (
          <p>Password reset link will be sent to below email address.</p>
        )}
      </Caption>
      {!emailSent && (
        <>
          <Form
            style={{
              width: "100%",
            }}
            onFinish={getCaptcha}
          >
            <Form.Item
              rules={[
                { required: true, message: "Enter a value." },
                { type: "email", message: "Enter an email." },
              ]}
              name="email"
              label="Email"
              labelCol={{ span: 24, style: { fontWeight: 600 } }}
              help={error ? errorMsg : undefined}
              validateStatus={error ? "error" : undefined}
            >
              <Input placeholder="" style={{ width: "100%", height: "34px" }} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                style={{ width: "200px", height: "36px", margin: "auto" }}
                htmlType="submit"
                loading={loading}
                // onClick={() => alert("Clicked submit")}
                disabled={forgetFlag}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
          {forgetFlag && (
            <ReCAPTCHA
              sitekey={siteKey}
              onChange={(value) => onCaptchaChange(value as string)}
            />
          )}
          <Button
            className="default-button-class"
            type="default"
            style={{ width: "200px", height: "36px" }}
            onClick={() => history.push("/")}
          >
            Go back to the Main page
          </Button>
        </>
      )}
    </div>
  );
};

export default ForgotPassword;
