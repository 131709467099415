import React, { useEffect, useState } from "react";
import "./App.scss";
import Keycloak, { KeycloakInitOptions } from "keycloak-js";
import { Constants, getReactAppBaseDomain } from "../../constants";
import {
  Console,
  // getKeycloackRedirectUri,
  getRealmFromUrl,
  keycloakInit,
} from "../../helpers";
import { LoadingSpinner } from "../common";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { RenderOnAuthenticated } from "../RenderOnAuthenticated";
import LandingPage from "../LandingPage/ui/LandingPage";
import { ProtectedRoutes } from "../Routes/protectedRoutes";
import { ToastContainer } from "react-toastify";
import qs from "qs";

function App() {
  const [keycloakInstance, setKeycloakInstance] = useState<Keycloak>();
  const [initOptions, setInitOptions] = useState<KeycloakInitOptions>({});
  const [loading, setLoading] = useState(true);
  const [isEmail, setIsEmail] = useState<string>(() => {
    const { search } = window.location;
    const realmFromQs = qs.parse(search, { ignoreQueryPrefix: true }).email;
    return realmFromQs?.toString() as string;
  });
  const [realmInit, setRealmInit] = useState<string | null>(() => {
    console.log({
      conditionForReplace:
        window.location.hostname === Constants.REACT_APP_BASE_DOMAIN,
    });
    if (window.location.hostname === Constants.REACT_APP_BASE_DOMAIN) {
      return null;
    }
    return getRealmFromUrl();
  });
  // const [realmInitUrl] = useState<string | null>(() => {
  //   if (window.location.hostname === Constants.REACT_APP_BASE_DOMAIN) {
  //     return null;
  //   }
  //   return getRealmFromUrl();
  // });
  const handleRealm = async (realmName: string, email: string) => {
    // const redirectUri = getKeycloackRedirectUri(realmName);
    // console.log("xxxxxxxxxxxxxxxxxxxxxxxx", { email });
    // const redirectUriWithEmail = `${redirectUri}/products?email=${email}`;
    // console.log({ email, redirectUri, realmName, redirectUriWithEmail });
    // window.location.href = redirectUriWithEmail;
    setLoading(false);
    // localStorage.setItem("emailRealm", realmName);
    const { initOption } = await keycloakInit(
      realmName,
      setKeycloakInstance,
      setInitOptions,
      email
    );
    // console.log(email);
    setRealmInit(realmName);
    setInitOptions(initOption);
  };
  // useEffect(() => {
  //   setLoading(false);

  //   if (realmFromQs !== "" && realmFromQs !== " " && realmFromQs) {
  //     setRealmInit(realmFromQs.toString());
  //   }
  // }, []);
  useEffect(() => {
    // setLoading(false);
    if (realmInit && !keycloakInstance) {
      console.log("keycloak init");
      keycloakInit(realmInit, setKeycloakInstance, setInitOptions, isEmail);
      setRealmInit(realmInit);
    }
    setLoading(false);
    // setKeycloakInstance(keycloakInstance);
    // setInitOptions(initOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realmInit]);
  console.log({
    url: Constants.KEY_CLOAK_AUTH_URL,
    realm: getRealmFromUrl(),
    realmInit,
    baseDomain: getReactAppBaseDomain(),
    clientId: Constants.KEY_CLOAK_CLIENT_ID,
  });
  return (
    <main className="main-container">
      <LoadingSpinner className="global-loading-spin" spinning={loading}>
        {keycloakInstance && (
          <ReactKeycloakProvider
            authClient={keycloakInstance}
            initOptions={initOptions}
            // autoRefreshToken={false}
            LoadingComponent={
              <LoadingSpinner
                className="main-container-react-loader"
                style={{ height: "100%" }}
                spinning
              />
            }
            onEvent={(e, t) => Console.log({ e, t })}
            onTokens={(token) => Console.log({ token })}
          >
            <RenderOnAuthenticated email={isEmail}>
              <>
                <div className={`page-container`}>
                  <ProtectedRoutes />
                </div>
              </>
            </RenderOnAuthenticated>
          </ReactKeycloakProvider>
        )}
        {!realmInit && !keycloakInstance?.authenticated && (
          <>
            <LandingPage setIsEmail={setIsEmail} handleRealm={handleRealm} />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              draggable={false}
              closeButton={false}
              hideProgressBar
              className="react-toastify-component"
            />
          </>
        )}
      </LoadingSpinner>
    </main>
  );
}

export default App;
