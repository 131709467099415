import React from "react";
import { useAuth } from "../../../hooks";

interface Props {
  children: React.ReactElement;
  email: string;
  realm?: string | null;
}

const RenderOnAuthenticated = ({ children, email }: Props) => {
  const { initialized, isAuthenticated, login, getEmail } = useAuth();

  console.log("email from the render authenticai", {
    email,
    initialized,
    isAuthenticated,
    emailToke: getEmail(),
  });
  if (initialized && !isAuthenticated) {
    console.log("called");
    login?.({ loginHint: email ? email.trim() : "" });
    return null;
  }
  return initialized && isAuthenticated ? <>{children}</> : null;
};

export default RenderOnAuthenticated;
