import { useForm } from "antd/es/form/Form";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import * as yup from "yup";
import { useEffect, useState } from "react";
import {
  getTempUser,
  signUpUrl,
  updateTempUser,
} from "../../../../../../helpers/RestApisHelper/RestApis";
import _ from "lodash";
import {
  decodeBase64,
  encodeBase64,
} from "../../../../../../constants/SAASFlowConstants";

const useSignUp = () => {
  const [form] = useForm();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationMsg, setValidationMsg] = useState<string>("");
  const restrictEmailProviders = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "live.com",
    "aol.com",
    "icloud.com",
    "mailinator.com",
    "trashmail.com",
    "10minutemail.com",
    "guerrillamail.com",
    "tempmail.com",
  ];
  const handleSignUp = (data: any) => {
    setLoading(true);
    const domain = data?.email?.split("@")[1];
    const isRestrictedDomain = restrictEmailProviders.includes(domain);
    if (isRestrictedDomain) {
      setError(isRestrictedDomain);
      setValidationMsg("Please enter valid Work Email.");
      setLoading(false);
      return;
    }
    setValidationMsg("");

    if (!_.isEmpty(id) && id !== "") {
      const emailId = decodeBase64(id);
      if (data.email === emailId) {
        updateUser(data);
        return;
      }
    }

    const url = signUpUrl;
    data.enquiries = [
      {
        product: "DDE",
        package: "Trial",
      },
    ];
    console.log("data", JSON.stringify(data));
    axios
      .post(url, data)
      .then((res: any) => {
        setLoading(false);
        setError(false);
        console.log("info: user registered successful", res);

        if (res?.data?.success) {
          setSuccess(true);
          const encodedEmail = encodeBase64(data?.email);
          const redirectURL = res?.data?.redirectToAdditionalDetails
            ? `/additionalDetails/${encodedEmail}`
            : `/validation/${encodedEmail}`;
          history.push(redirectURL);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setSuccess(false);
        setValidationMsg(err?.response?.data?.error ?? "Please try again.");
        console.log({ err }, err?.response?.data?.success);
      });
  };

  const updateUser = (data: any) => {
    axios
      .post(updateTempUser, data)
      .then((res: any) => {
        setLoading(false);
        setError(false);
        console.log("info: userUpdated:", res);
        if (res.data.message.includes("success")) {
          setSuccess(true);
          const encodedEmail = encodeBase64(data?.email);
          history.push(`/validation/${encodedEmail}`);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setSuccess(false);
        setValidationMsg(err?.response?.data?.error ?? "Please try again.");
        console.log({ err }, err?.response?.data?.success);
        console.log("error: UpdateUser", err);
      });
  };
  const emailSchema = yup.object().shape({
    email: yup.string().email(),
  });
  const yupSyncEmail = {
    async validator({ field }: { field: any }, value: any) {
      await emailSchema.validateSyncAt(field, { [field]: value });
    },
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUserData = () => {
    console.log(id);
    const emailId = decodeBase64(id);
    axios.get(`${getTempUser}${emailId}`).then((res: any) => {
      console.log("userData", res?.data?.existingTempUser);
      form.setFieldsValue(res?.data?.existingTempUser);
    });
  };

  useEffect(() => {
    if (!_.isEmpty(id) && id !== "") {
      console.log({ id });
      getUserData();
    }
  }, [getUserData, id]);

  return {
    form,
    handleSignUp,
    yupSyncEmail,
    success,
    loading,
    error,
    validationMsg,
    history,
    signUpUrl,
  };
};

export default useSignUp;
