export const resend_OTP_Ms = 30;

export const encodeBase64 = (str: string) => {
  return window.btoa(str);
};

export const decodeBase64 = (str: string) => {
  return window.atob(str);
};

export interface EmailRouteParams {
  email: string;
}
