import { useEffect } from "react";
import { ReactComponent as SuccessResponse } from "../../../../assets/svg/SuccessResponse.svg";
import "./SubmitResponse.scss";
import { useParams, useHistory } from "react-router-dom";

const SubmitResponse = () => {
  const history = useHistory();
  const { subscription } = useParams<{
    subscription: string;
  }>();

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === "POP") {
        // Prevent the back navigation
        return false;
      }
    });

    return () => {
      unblock();
    };
  }, [history]);

  return (
    <div className="success-response">
      <div className="success-response-img">
        <SuccessResponse />
      </div>
      <div>
        <p className=" response-msg">
          {subscription === "FreeTrail"
            ? `We've received your details. We will share the login information with
          you over your email soon.`
            : `Thank you! We've received your details. The Terms and Conditions and SLA will be sent to your email. Please review and sign them, and your login information will follow shortly.`}
        </p>
      </div>
    </div>
  );
};

export default SubmitResponse;
