import React from "react";

import { Spin, SpinProps } from "antd";
import Lottie from "react-lottie";

import * as animationData from "../../../../assets/lotties/aeNormalCircle.json";
import "./loading.component.scss";

interface Props extends SpinProps {
  className?: string;
  spinning?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  animation?: any;
}

const LoadingSpinner: React.FC<Props> = ({
  children,
  className = "",
  spinning,
  animation = animationData,
  wrapperClassName = "",
  ...rest
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Spin
      spinning={spinning}
      indicator={<Lottie options={defaultOptions} height={32} width={32} />}
      className={`${className} flex justify-center items-center`}
      wrapperClassName={`${wrapperClassName} ae-loading w-full h-full`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </Spin>
  );
};

export default LoadingSpinner;
