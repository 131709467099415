export const fetchUser = async (url: string, data: string | any) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: data,
  });
  if (!response.ok) {
    const message = `Realm not found: ${response.status}`;
    throw new Error(message);
  }
  const realmsFromEmail = await response.json();
  return realmsFromEmail;
};

export const fetchData = async (url: string, data: string | any) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: data,
  });
  if (!response.ok) {
    const errorData = await response.json();
    const message = `${errorData?.message || errorData?.error}`;
    throw new Error(message);
  }
  const realmsFromEmail = await response.json();
  return realmsFromEmail;
};
