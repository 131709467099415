import { useForm } from "antd/es/form/Form";
import { signUpUrl } from "../../../../helpers/RestApisHelper/RestApis";
import axios from "axios";
import * as yup from "yup";
import { useState } from "react";

const useSignUp = () => {
  const [form] = useForm();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSignUp = (data: any) => {
    setLoading(true);
    const url = signUpUrl;
    data.number = Number(data.number);
    // const str = JSON.stringify(data);
    axios
      .post(url, data)
      .then((res: any) => {
        setLoading(false);
        setError(false);

        if (res.data.success) {
          setSuccess(true);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setSuccess(false);
      });
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const phoneNumberSchema = yup.object().shape({
    number: yup
      .string()
      .required()
      .matches(phoneRegExp, "Phone number is not valid"),
  });
  const emailSchema = yup.object().shape({
    email: yup.string().email(),
  });
  const yupSyncPhoneNumber = {
    async validator({ field }: { field: any }, value: any) {
      await phoneNumberSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const yupSyncEmail = {
    async validator({ field }: { field: any }, value: any) {
      await emailSchema.validateSyncAt(field, { [field]: value });
    },
  };
  return {
    form,
    handleSignUp,
    yupSyncPhoneNumber,
    yupSyncEmail,
    success,
    loading,
    error,
  };
};

export default useSignUp;
