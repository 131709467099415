import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import DEFIMG from "../../../assets/imgs/customReportDefault.jpg";
import "./ReportCard.scss";
import { Edit2 } from "react-feather";
import "./ReportCard.scss";
interface Props {
  reportName: string;
  id: string;
  url?: string;
  desc?: string;
  clickId: number;
  product?: string;
  createdOn?: string;
}

const ReportCard = ({
  id,
  reportName,
  url,
  desc,
  clickId,
  createdOn,
  product,
}: Props) => {
  const history = useHistory();
  const handleReport = () => {
    history.push(`report/${clickId}`);
  };
  return (
    <div
      role="button"
      className={`report-card`}
      onClick={() => handleReport()}
      onKeyDown={() => handleReport()}
    >
      <div className="report-card-img">
        <img src={url ? url : DEFIMG} alt="report-img" />
      </div>
      <div className="report-card-name">
        <p>{_.capitalize(reportName)}</p>
      </div>
      <div className="report-card-prod">
        <p>{product ? product : "DDE"}</p>
      </div>
      <div className="report-card-desc">
        <p>Description</p>
        <p>{desc ? desc : ""}</p>
      </div>
      <div className="report-card-created-on">
        <div>
          <Edit2 size={16} />
        </div>
        <div>Created on {createdOn ? createdOn : ""}</div>
      </div>
    </div>
  );
};

export default ReportCard;
