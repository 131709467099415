import _ from "lodash";
import React, { useEffect } from "react";
import "./RealmsList.scss";
import AeLogoSmall from "../../../../assets/imgs/AE_Qube_color_rgb.png";
import { LoadingSpinner } from "../../../common";

interface Props {
  setIsEmailFetch?: React.Dispatch<React.SetStateAction<boolean>>;
  realmsFromFetch: { id: number; name: string }[] | undefined;
  handleRealm(realm: string): void;
  email?: string;
}

const RealmsList = ({
  handleRealm,
  realmsFromFetch,
  setIsEmailFetch,
  email,
}: Props) => {
  useEffect(() => {
    if (realmsFromFetch && realmsFromFetch?.length === 1) {
      handleRealm(realmsFromFetch[0].name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realmsFromFetch]);
  // if (realmsFromFetch && realmsFromFetch?.length === 1) {
  //   handleRealm(realmsFromFetch[0].name);
  //   return <></>;
  // }
  return (
    <div className="realm-list-container">
      <div className="realms-list-inner-container">
        {realmsFromFetch && realmsFromFetch?.length > 1 ? (
          <>
            <div className="realm-list-container-header">
              <div>
                <img src={AeLogoSmall} alt="ae-qube" />
              </div>
              {/* <div>
                <Button
                  className="default-button-class"
                  style={{ width: "90px", height: "36px" }}
                  onClick={() => setIsEmailFetch && setIsEmailFetch(false)}
                >
                  Back
                </Button> */}
              {/* </div> */}
            </div>
            <div className="realm-list-container-text">
              <p>Please choose a Tenant.</p>
            </div>
            <div className="realm-list-container-tenant-list-container">
              {realmsFromFetch?.map((realmName) => {
                return (
                  // eslint-disable-next-line jsx-a11y/interactive-supports-focus
                  <div
                    role="button"
                    className="realm-card"
                    onClick={() => handleRealm(realmName.name)}
                    onKeyDown={() => handleRealm(realmName.name)}
                  >
                    <div>
                      <p>{_.capitalize(realmName.name)}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <LoadingSpinner spinning />
        )}
      </div>
    </div>
  );
};

export default RealmsList;
