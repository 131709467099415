import { useKeycloak } from "@react-keycloak/web";

export const useAuth = () => {
  const { keycloak, initialized } = useKeycloak();

  const {
    login,
    logout,
    hasRealmRole,
    isTokenExpired,
    clearToken,
    authenticated,
    refreshToken,
    token,
    refreshTokenParsed,
    tokenParsed,
    hasResourceRole,
    idTokenParsed,
    realm,
  } = keycloak;

  const updateToken = async (successCallback: (data: boolean) => void) =>
    keycloak.updateToken(10).then(successCallback).catch(login);

  const getUsername = () => tokenParsed?.preferred_username;
  const getEmail = () => tokenParsed?.email;
  const getName = () => tokenParsed?.name;
  const getFirstName = () => tokenParsed?.first_name;
  const getCurrentRealm = () => realm;
  const isAuthorizedCheckPermissions = (requiredPermissions?: string[]) => {
    if (keycloak && requiredPermissions) {
      return requiredPermissions.some((r) => {
        const realm = hasRealmRole(r);
        const resource = hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };
  const getEmailFromToken = () => {
    return idTokenParsed?.email;
  };
  return {
    initialized,
    keycloak,
    login,
    logout,
    hasRealmRole,
    isTokenExpired,
    updateToken,
    clearToken,
    isAuthenticated: authenticated,
    refreshToken,
    token,
    refreshTokenParsed,
    tokenParsed,
    getUsername,
    isAuthorizedCheckPermissions,
    getName,
    getEmailFromToken,
    getEmail,
    getFirstName,
    idTokenParsed,
    getCurrentRealm,
  };
};
