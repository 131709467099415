import { Modal, Button } from "ae-ui-components";
import Title from "antd/es/typography/Title";

import "./TermsConditionsModal.scss";

export interface TCProps {
  handleTermsAgree: () => void;
  showTermsModal: boolean;
}

const TermsConditionsModal = (props: TCProps) => {
  const { handleTermsAgree, showTermsModal } = props;
  return (
    <Modal
      className="terms-modal-content"
      isOpen={showTermsModal}
      size="small"
      title="Terms and Conditions"
      footer={false}
    >
      <div className="scrollable-container">
        <div className="item">
          <Title level={5}>Introduction</Title>
          Explore contribution, decomposition, ROI, and effectiveness charts for
          any time period within the model duration. You can click on the charts
          for more granular view. We've got a budget allocator to optimize your
          marketing spend. The system sets constraints at 50-150% of the spends.
          For more flexibility, head over to the simulation module. We've got a
          budget allocator to optimize your marketing spend. The system sets
          constraints at 50-150% of the spends.
        </div>
        <div className="item">
          <Title level={5}>Terms of Use</Title>
          Explore contribution, decomposition, ROI, and effectiveness charts for
          any time period within the model duration. You can click on the charts
          for more granular view. We've got a budget allocator to optimize your
          marketing spend. The system sets constraints at 50-150% of the spends.
          For more flexibility, head over to the simulation module. We've got a
          budget allocator to optimize your marketing spend. The system sets
          constraints at 50-150% of the spends.
        </div>
        <Button
          className="w-100 btn-agree"
          onClick={handleTermsAgree}
          type="default"
        >
          I Agree
        </Button>
      </div>
    </Modal>
  );
};

export default TermsConditionsModal;
