import {
  getApiUrl,
  getReactAppBaseDomain,
  getKeycloakAuthUrl,
  getRealm,
  getReactAppKeycloakClientId,
  getUserManagementEndpoint,
} from "./dynamicHelpers";

export const Constants = {
  KEY_CLOAK_CLIENT_ID: getReactAppKeycloakClientId(),
  KEY_CLOAK_REALM_NAME: getRealm(),
  KEY_CLOAK_AUTH_URL: getKeycloakAuthUrl(),
  REACT_APP_BASE_DOMAIN: getReactAppBaseDomain(),
  REACT_APP_API_URL: getApiUrl(),
  REACT_APP_USER_MANAGEMENT_URL: getUserManagementEndpoint(),
};
