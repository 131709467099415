import React, { useState } from "react";
import "./ConfirmPassword.scss";
import { Form } from "antd";
import { Button, Input } from "ae-ui-components";
import { AeLogo } from "../../../assets";
import { resetPassword } from "../../../helpers/RestApisHelper/RestApis";
import { fetchData } from "../../LandingPage/functions/fetchUser";
import { useHistory, useParams } from "react-router-dom";

const ConfirmPassword = () => {
  const history = useHistory();
  const { token, userId } = useParams<{ token: string; userId: string }>();
  const [loading, setLoding] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleConfirmPassword = async (vals: any) => {
    setLoding(true);
    if (vals.password) {
      const url = resetPassword;
      const data = JSON.stringify({
        password: vals.password,
        resetPasswordToken: `${userId}/${token}`,
      });
      await fetchData(url, data)
        .then((val) => {
          console.log({ val });
          setLoding(false);
          setError(false);
          history.push("/");
        })
        .catch((err) => {
          setLoding(false);
          setError(true);
          setErrorMsg(err?.message);
        });
    }
  };

  return (
    <div className="confirm-password-container">
      <div className="email-input-header">
        <AeLogo />
      </div>{" "}
      <Form
        style={{
          width: "100%",
        }}
        labelCol={{ span: 24 }}
        onFinish={handleConfirmPassword}
      >
        <Form.Item
          name="password"
          label="Password"
          style={{ width: "100%" }}
          labelCol={{ span: 24, style: { fontWeight: 600 } }}
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
          validateStatus={error ? "error" : undefined}
          help={error ? errorMsg : undefined}
        >
          <Input
            allowClear={false}
            style={{ width: "100%" }}
            id="password"
            type="password"
            placeholder="Enter password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={["password"]}
          labelCol={{ span: 24, style: { fontWeight: 600 } }}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input
            id="confirm_password"
            style={{ width: "100%" }}
            type="password"
            placeholder="Confirm Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            style={{ width: "200px", height: "36px", margin: "auto" }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ConfirmPassword;
