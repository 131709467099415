import Keycloak, { KeycloakInitOptions } from "keycloak-js";

import { Constants } from "../../constants";

const getRealmFromUrl = (): string | null => {
  const realmArr = window.location.hostname
    .replace(Constants.REACT_APP_BASE_DOMAIN, "")
    .slice(0, -1)
    .split(".");
  console.log({
    realmArr,
    hostname: window.location.hostname,
    baseDomain: Constants.REACT_APP_BASE_DOMAIN,
  });
  if (realmArr.length > 1) {
    return null;
  }
  return realmArr[0];
};

const getKeycloackRedirectUri = (
  realm: string,
  address = window.location.origin,
  byPass = false
): string => {
  const windowOrigin = address;
  if (realm === getRealmFromUrl() && byPass === false) {
    return windowOrigin;
  }
  const [protocol, hostName] = windowOrigin.split("://");

  const redirectUri = `${protocol}://${realm}.${hostName}`;
  return redirectUri;
};

const keycloakInit = async (
  realm: string,
  setKeycloakInstance: any,
  setInitOptions: any,
  email: string,
  shouldLogin?: boolean
) => {
  const redirectUri = getKeycloackRedirectUri(realm);
  const initOption: KeycloakInitOptions = {
    // onLoad: email ? "login-required" : "check-sso",
    onLoad: "check-sso",
    // silentCheckSsoRedirectUri: `${redirectUri}/products`,
    pkceMethod: "S256",
    enableLogging: true,
    checkLoginIframe: true,
    redirectUri: `${redirectUri}`,
  };
  setInitOptions(initOption);
  const keycloakInstance = await new Keycloak({
    url: Constants.KEY_CLOAK_AUTH_URL,
    realm,
    clientId: Constants.KEY_CLOAK_CLIENT_ID,
  });

  if (shouldLogin) {
    console.log("in int", { keycloakInstance });
    keycloakInstance.login?.({
      loginHint: email.trim(),
      redirectUri: `${redirectUri}/products`,
    });
  }
  setKeycloakInstance(keycloakInstance);

  return { redirectUri, initOption, keycloakInstance };
};

// const doLogin = () => keycloakGlobalInstance.login();

// const isLoggedIn = (): boolean => !!keycloakGlobalInstance.token;

// const updateToken = (callback: any) => {
// 	keycloakGlobalInstance.updateToken(5).then(callback).catch(doLogin);
// };

// const getToken = (): string | undefined => keycloakGlobalInstance.token;

// const setTokenGlobal = (keycloak: Keycloak.KeycloakInstance) => {

// };
export {
  getKeycloackRedirectUri,
  getRealmFromUrl,
  keycloakInit,
  // updateToken,
  // isLoggedIn,
  // getToken,
};
