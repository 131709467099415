import { Input } from "ae-ui-components";
import { Form, Button, Select } from "antd";
import Title from "antd/es/typography/Title";
import "./AdditionalDetails.scss";
import _ from "lodash";
import useAdditionalDetails from "../hooks/useAdditionalDetails";
import { TermsConditionsModal } from "../../TermsConditionsModal";

const AdditionalDetails = () => {
  const { Option } = Select;
  const {
    form,
    showTermsModal,
    termsAccepted,
    setShowTermsModal,
    errorMsg,
    handleSubmit,
    handleTermsAgree,
    setErrorMsg,
    loading,
  } = useAdditionalDetails();

  return (
    <div className="additional-details-container">
      <TermsConditionsModal
        showTermsModal={showTermsModal}
        handleTermsAgree={handleTermsAgree}
      />

      <Title level={3}>Provide Additional Details</Title>
      <Form
        form={form}
        onFinish={handleSubmit}
        className="free-trail-additional-details-form"
      >
        <Form.Item
          rules={[{ required: true, message: "This field is required." }]}
          required
          name="companyName"
          label="Company Name"
          labelCol={{ span: 24, style: { fontWeight: 600 } }}
        >
          <Input placeholder="Enter your Company Name" className="form-input" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "This field is required." }]}
          required
          name="industryType"
          label="Industry"
          labelCol={{ span: 24, style: { fontWeight: 600 } }}
        >
          <Select
            placeholder="Select your Industry"
            className="select-industry"
            // onChange={this.onGenderChange}
          >
            <Option value="CPG-Food and Beverage">CPG</Option>
            <Option value="DigitalNative-Food Delivery">DigitalNative</Option>
            <Option value="Gaming-Puzzle Gaming">Gaming</Option>
            <Option value="Automotive-Manufacturing">Automotive</Option>
            <Option value="Healthcare-Pharma">Healthcare</Option>
            <Option value="Telecom-Telecom">Telecom</Option>
            <Option value="Ecommerce-Electronics">Ecommerce</Option>
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "This field is required." }]}
          required
          name="country"
          label="Country"
          labelCol={{ span: 24, style: { fontWeight: 600 } }}
        >
          <Select
            placeholder="Select your Country"
            className="select-industry"
            // onChange={this.onGenderChange}
          >
            <Option value="India">India</Option>
            <Option value="USA">USA</Option>
            <Option value="other">Other</Option>
          </Select>
        </Form.Item>
        <Form.Item
          required
          rules={[{ required: true, message: "This field is required." }]}
        >
          <div className="flex terms-btn-container">
            <input
              type="radio"
              title="Please review and agree to the Terms to proceed"
              className=" hover-btn-animate terms-radio-btn"
              checked={termsAccepted}
              onClick={() => {
                setErrorMsg(
                  termsAccepted
                    ? ""
                    : "Please review and agree to the Terms to proceed "
                );
              }}
            />
            <div>
              <span className={!termsAccepted ? "hover-btn-animate" : ""}>
                I agree with the
              </span>
              <Button
                type="text"
                className="link-color terms-btn"
                onClick={() => setShowTermsModal(true)}
              >
                <u>Terms and Conditions</u>
              </Button>
            </div>
          </div>
        </Form.Item>
        <Form.Item
          validateStatus="error"
          help={_.isEmpty(errorMsg) ? "" : errorMsg}
        >
          <Button
            loading={loading}
            className="w-100 btn-radius-4"
            htmlType="submit"
            type={termsAccepted ? "primary" : "default"}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdditionalDetails;
