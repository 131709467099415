import { Avatar } from "antd";
import React, { useRef, useState } from "react";
import _ from "lodash";

import "./ProfileMenu.component.scss";
import { MenuItem } from "../../../types/projectMenu";
import { useOnClickOutside } from "../../../hooks/Profile/useClickOutside";

interface Props {
  avatar: React.ReactNode;
  items: MenuItem[];
}

const ProfileMenu: React.FC<Props> = ({ avatar, items }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const handleShowHideMenu = () => {
    if (!_.isEmpty(items)) setShowMenu((prev) => !prev);
  };
  useOnClickOutside(ref, handleShowHideMenu);

  return (
    <div className="ae-navbar-profile relative">
      {!showMenu ? (
        <div
          onClick={handleShowHideMenu}
          onKeyDown={handleShowHideMenu}
          role="button"
          tabIndex={0}
          className="ae-navbar-profile-avatar-container"
        >
          <Avatar
            className="ae-navbar-profile-avatar cursor-pointer flex items-center justify-center text-primary"
            icon={avatar}
            size={32}
          />
        </div>
      ) : (
        <div ref={ref} className="ae-navbar-profile-menu absolute">
          <div
            onClick={handleShowHideMenu}
            onKeyDown={handleShowHideMenu}
            role="button"
            tabIndex={0}
            className="ae-navbar-menu-avatar-container absolute"
          >
            <Avatar
              className="ae-navbar-menu-avatar cursor-pointer flex items-center justify-center text-primary"
              icon={avatar}
              size={32}
            />
          </div>
          {items?.map((item) =>
            item.children ? (
              []
            ) : (
              <div
                onClick={item.onClick}
                onKeyDown={item.onClick}
                role="button"
                tabIndex={0}
                className="ae-navbar-profile-menu-item"
                key={item.key}
                title={item.label}
              >
                {item.label}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;
