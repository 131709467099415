import { useKeycloak } from "@react-keycloak/web";
import { KeycloakTokenParsed, KeycloakInstance } from "keycloak-js";

interface ParsedToken extends KeycloakTokenParsed {
  name?: string;
  preferred_username?: string;
  given_name?: string;
  family_name?: string;
}

const getParsedToken = (_kc?: KeycloakInstance): ParsedToken | undefined =>
  _kc?.tokenParsed;

export const useUserAvatar = (): { name: string; avatar: string } => {
  const { keycloak } = useKeycloak();

  const name = getParsedToken(keycloak)?.name;
  const names = name?.split(" ");
  if (names)
    return {
      avatar: names[0]
        .charAt(0)
        .concat(names[names.length - 1].charAt(0))
        .toUpperCase(),
      name: names[0],
    };
  return { name: "", avatar: "" };
};
