import dde from "../../assets/svg/projectSvgs/dde.svg";
import priceSense from "../../assets/svg/projectSvgs/pricesense.svg";
import testing from "../../assets/svg/projectSvgs/testing.svg";
import customReports from "../../assets/svg/projectSvgs/customreports.svg";
import { ProjectAtrributesObject } from "../../types/projectAttributes";
import {
  CUSTOM_REPORTS_PRODUCT_KEY,
  PRICESENSE_PRODUCT_KEY,
  DEMAND_DRIVER_PRODUCT_KEY,
  SYNTEST_PRODUCT_KEY,
} from "../../constants/productTextConstants";

const ProjectCardColors: ProjectAtrributesObject = {
  [DEMAND_DRIVER_PRODUCT_KEY]: {
    primaryColor: "#faa632",
    secondaryColor: "#d27832",
    fullNameWithCube: <img src={dde} alt={DEMAND_DRIVER_PRODUCT_KEY} />,
  },
  [PRICESENSE_PRODUCT_KEY]: {
    primaryColor: "#d3b172",
    secondaryColor: "#a58553",
    fullNameWithCube: <img src={priceSense} alt={PRICESENSE_PRODUCT_KEY} />,
  },
  [SYNTEST_PRODUCT_KEY]: {
    primaryColor: "#98ba56",
    secondaryColor: "#6e8c37",
    fullNameWithCube: <img src={testing} alt={SYNTEST_PRODUCT_KEY} />,
  },
  [CUSTOM_REPORTS_PRODUCT_KEY]: {
    primaryColor: "#46aa9b",
    secondaryColor: "#3a8478",
    fullNameWithCube: (
      <img src={customReports} alt={CUSTOM_REPORTS_PRODUCT_KEY} />
    ),
  },
};

const CommonColors = {
  right: "#216ca0",
  left: "#494a49",
};

export { CommonColors, ProjectCardColors };
