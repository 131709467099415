declare global {
  interface Window {
    REACT_APP_API_URL: string | undefined;
    REACT_APP_BASE_DOMAIN: string | undefined;
    REACT_APP_KEY_CLOAK_CLIENT_ID: string | undefined;
    REACT_APP_AUTH_SERVER_URL: string | undefined;
    REACT_APP_KEY_CLOAK_REALM_NAME: string | undefined;
    REACT_APP_USER_MANAGEMENT_URL: string | undefined;
    REACT_APP_TENANT_MANAGEMENT_V2_URL: string | undefined;
    REACT_APP_PRODUCTS_LIST: string | undefined;
    REACT_APP_RECAPTCHA_SITE_KEY: string | undefined;
  }
}
const getApiUrl = (): string => {
  if (window.REACT_APP_API_URL) {
    return window.REACT_APP_API_URL;
  }
  return process.env.REACT_APP_API_URL ?? "";
};
const getReactAppBaseDomain = (): string => {
  console.log({ window });
  if (window.REACT_APP_BASE_DOMAIN) {
    return window.REACT_APP_BASE_DOMAIN;
  }
  return process.env.REACT_APP_BASE_DOMAIN ?? "";
};
const getReactAppKeycloakClientId = (): string => {
  if (window.REACT_APP_KEY_CLOAK_CLIENT_ID) {
    return window.REACT_APP_KEY_CLOAK_CLIENT_ID;
  }
  return process.env.REACT_APP_KEY_CLOAK_CLIENT_ID ?? "";
};
const getKeycloakAuthUrl = (): string => {
  if (window.REACT_APP_AUTH_SERVER_URL) {
    return window.REACT_APP_AUTH_SERVER_URL;
  }
  return process.env.REACT_APP_AUTH_SERVER_URL ?? "";
};
const getRealm = (): string => {
  if (window.REACT_APP_KEY_CLOAK_REALM_NAME) {
    return window.REACT_APP_KEY_CLOAK_REALM_NAME;
  }
  return process.env?.REACT_APP_KEY_CLOAK_REALM_NAME ?? "";
};
const getUserManagementEndpoint = (): string => {
  if (window.REACT_APP_USER_MANAGEMENT_URL) {
    return window.REACT_APP_USER_MANAGEMENT_URL;
  }
  return process.env?.REACT_APP_USER_MANAGEMENT_URL ?? "";
};
const getTenantOrgDetailsEndpoint = (): string => {
  if (window.REACT_APP_TENANT_MANAGEMENT_V2_URL) {
    return window.REACT_APP_TENANT_MANAGEMENT_V2_URL;
  }
  return process.env?.REACT_APP_TENANT_MANAGEMENT_V2_URL || "";
};

const getProductsList = (): string => {
  if (window.REACT_APP_PRODUCTS_LIST) {
    return window.REACT_APP_PRODUCTS_LIST;
  }
  return process.env.REACT_APP_PRODUCTS_LIST || "";
};
const getRecaptchaSiteKey = (): string => {
  if (window.REACT_APP_RECAPTCHA_SITE_KEY) {
    return window.REACT_APP_RECAPTCHA_SITE_KEY;
  }
  return process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";
};
export {
  getApiUrl,
  getReactAppBaseDomain,
  getKeycloakAuthUrl,
  getRealm,
  getReactAppKeycloakClientId,
  getUserManagementEndpoint,
  getTenantOrgDetailsEndpoint,
  getProductsList,
  getRecaptchaSiteKey,
};
