import { useEffect, useState } from "react";
import { getAllReportsUrl } from "../../../helpers/RestApisHelper/RestApis";
import { fetchUser } from "../../../components/LandingPage/functions/fetchUser";
import { useAuth } from "../../../hooks";
import moment from "moment";
import { Base64 } from "js-base64";

import { ProductType } from "../../projects/ui/ProductsList";
import { getProductsList } from "../../../constants";
import { Console, getRealmFromUrl } from "../../../helpers";
import _ from "lodash";

const useReportsPage = () => {
  const { getEmail } = useAuth();
  const [loading, setLaoding] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [allproductsFiltered] = useState<ProductType[]>(
    JSON.parse(
      Base64.decode(getProductsList())
        .toString()
        .trim()
        .replace(/&quot;/gi, '"')
    )
      .map((each: any) => {
        Console.log("Name", each);
        return {
          name: each.name,
          desc: each.desc,
          baseUrl: each.baseUrl,
          learnMore: each.learnMore,
          id: each.id,
        };
      })
      .filter((each: any) => !each.id.includes("custom"))
  );
  const [productMapping] = useState(_.keyBy(allproductsFiltered, "id"));
  const [productOptions] = useState(() => [
    { label: "All", value: "all" },
    ...allproductsFiltered.map((each) => ({
      value: each.id,
      label: each.name,
    })),
  ]);
  console.log({ allproductsFiltered });
  const [reportsDataInitial, setReportsDataInitial] = useState<
    {
      reportName: string;
      reportId: string;
      desc: string;
      imgUrl: string;
      id: number;
      product?: string;
      createdOn?: string;
      createdAt?: string;
    }[]
  >([]);
  const [reportsData, setReportsData] = useState<
    {
      reportName: string;
      reportId: string;
      desc: string;
      imgUrl: string;
      id: number;
      product?: string;
      createdOn?: string;
      createdAt?: string;
    }[]
  >([]);
  const onChangeSearch = (val: string) => {
    setSearchString(val.trim() || "");
    if (val) {
      setReportsData(
        reportsDataInitial.filter((each) =>
          each.reportName.includes(val.trim())
        )
      );
    } else {
      setReportsData(reportsDataInitial);
    }
  };
  const onChangeProduct = (val: string) => {
    try {
      setLaoding(true);
      const url = getAllReportsUrl;
      const email = getEmail();
      const tenantName = getRealmFromUrl();
      const productName = val;
      if (email) {
        if (val === "all") {
          fetchUser(url, JSON.stringify({ email: email.trim(), tenantName }))
            .then((reports) => {
              console.log({ reports });
              const newReports = reports.data.map((o: any) => ({
                createdOn: o.createdOn || "",
                product: productMapping[o.product.name].name || "",
                reportName: o.reportName,
                reportId: o.reportId,
                desc: o.description,
                imgUrl: o.reportBlobPath,
                id: o.id,
                createdAt: moment(o.createdAt).format("DD/MM/YYYY"),
              }));
              setReportsDataInitial(newReports);
              setReportsData(
                searchString
                  ? newReports.filter((each: any) =>
                      each.name.includes(searchString)
                    )
                  : newReports
              );
              setLaoding(false);
            })
            .catch((error) => {
              if (error) {
                console.log({ error });
              }
              setLaoding(false);
            });
        } else {
          fetchUser(
            url,
            JSON.stringify({ email: email.trim(), tenantName, productName })
          )
            .then((reports) => {
              console.log({ reports });
              const newReports = reports.data.map((o: any) => ({
                createdOn: o.createdOn || "",
                product: productMapping[o.product.name].name || "",
                reportName: o.reportName,
                reportId: o.reportId,
                desc: o.description,
                imgUrl: o.reportBlobPath,
                id: o.id,
                createdAt: moment(o.createdAt).format("DD/MM/YYYY"),
              }));
              setReportsDataInitial(newReports);
              console.log({ searchString });
              setReportsData(
                searchString
                  ? newReports.filter((each: any) =>
                      each.name.includes(searchString)
                    )
                  : newReports
              );
              setLaoding(false);
            })
            .catch((error) => {
              if (error) {
                console.log({ error });
              }
              setLaoding(false);
            });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    Console.log("Product List", getProductsList());
  }, []);

  useEffect(() => {
    setLaoding(true);
    const url = getAllReportsUrl;
    const email = getEmail();
    const tenantName = getRealmFromUrl();
    if (email) {
      fetchUser(url, JSON.stringify({ email: email.trim(), tenantName }))
        .then((reports) => {
          console.log({ reports });
          setReportsDataInitial(
            reports.data.map((o: any) => ({
              createdOn: o.createdOn || "",
              product: productMapping[o?.product?.name]?.name || "",
              reportName: o.reportName,
              reportId: o.reportId,
              desc: o.description,
              imgUrl: o.reportBlobPath,
              id: o.id,
              createdAt: moment(o.createdAt).format("DD/MM/YYYY"),
            }))
          );
          setReportsData(
            reports?.data?.map((o: any) => ({
              createdOn: o.createdAt || "",
              product: productMapping[o?.product?.name]?.name || "",
              reportName: o.reportName,
              reportId: o.reportId,
              desc: o.description,
              imgUrl: o.reportBlobPath,
              id: o.id,
              createdAt: moment(o.createdAt).format("DD/MM/YYYY"),
            }))
          );
          setLaoding(false);
        })
        .catch((error) => {
          if (error) {
            console.log({ error });
          }
          setLaoding(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    reportsData,
    loading,
    productOptions,
    onChangeProduct,
    onChangeSearch,
  };
};

export default useReportsPage;
