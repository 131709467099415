import { Form, Input, Button } from "antd";
import Title from "antd/es/typography/Title";
import { Edit2 } from "react-feather";
import "./OTPValidation.scss";
import { useOTPValidation } from "../hooks/useOTPValidation";

const OTPValidation = () => {
  const {
    handleSubmit,
    incorrectPassword,
    handleResend,
    disableResend,
    formatTime,
    disable,
    seconds,
    loading,
    otpValue,
    handleChange,
    inputFieldsRef,
    emailId,
    errorMsg,
    handleEditDetails,
  } = useOTPValidation();
  return (
    <div className="otp-validation-container">
      <div className="inner-otp-validation-container">
        <div className="otp-validator-header">
          <Title level={3}>Please Verify your Email</Title>
          <p className="font-13">
            Use the 6-digit code sent to your work email
          </p>

          <div className="flex inovua-react-toolkit-flex-v3--justify-content-left col-gap-5">
            <b className="email-id-container">{emailId}</b>
            <Button
              title="Edit details"
              onClick={handleEditDetails}
              className="edit-icon"
              icon={<Edit2 />}
            />
          </div>
        </div>
        <div className="otp-validation-form-container">
          <Form onFinish={handleSubmit} className="otp-validation-form">
            <Form.Item>
              <div className="flex otp-validation-form-inner ">
                {otpValue.map((value, index) => (
                  <Input
                    key={index}
                    value={value}
                    maxLength={1}
                    placeholder=""
                    onChange={(e) => handleChange(e, index)}
                    ref={(el) => (inputFieldsRef.current[index] = el)}
                    className={`otp-input-field ${
                      incorrectPassword ? `border-red` : ``
                    }`}
                  />
                ))}
              </div>
            </Form.Item>

            <Form.Item>
              <div className="flex justify-between gap-10">
                <span className="font-13 error-msg-color">
                  {incorrectPassword ? errorMsg : ""}
                </span>
                <div className="justify-items-end">
                  <div className="flex justify-between gap-5">
                    <Button
                      onClick={handleResend}
                      className={`otp-resend-button ${
                        disableResend ? `btn-disabled-color ` : `link-color`
                      }`}
                      disabled={disableResend}
                    >
                      Resend Code
                    </Button>
                    <span className={!disableResend ? "hide-timer" : ""}>
                      {formatTime(seconds)}
                    </span>
                  </div>
                </div>
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="form-submit-btn"
                disabled={disable}
                loading={loading}
              >
                Continue
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default OTPValidation;
