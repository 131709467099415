import { Message } from "ae-ui-components";
import { InputRef } from "antd";
import _ from "lodash";
import { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  decodeBase64,
  EmailRouteParams,
  resend_OTP_Ms,
} from "../../../../constants/SAASFlowConstants";
import {
  resendOTP,
  validateOTP,
} from "../../../../helpers/RestApisHelper/RestApis";
import axios from "axios";

export const useOTPValidation = () => {
  const history = useHistory();
  const { email } = useParams<EmailRouteParams>();
  const [otpValue, setOtpValue] = useState<string[]>(new Array(6).fill(""));
  const [loading, setLoading] = useState(false);
  const inputFieldsRef = useRef<(InputRef | null)[]>([]);
  const [disable, setDisable] = useState<boolean>(true);
  const [disableResend, setDisableResend] = useState<boolean>(true);
  const [incorrectPassword, setIncorrectPassword] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(resend_OTP_Ms);
  const [emailId, setEmailId] = useState<string>();
  const [errorMsg, setErrorMsg] = useState<string>();
  const intervalRef = useRef<number | null>(null);

  const handleChange = (element: any, index: number) => {
    const value = element.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otpValue];
      newOtp[index] = value;
      setOtpValue(newOtp);
      console.log({ newOtp });
      const isEmpty = _.some(newOtp, (value) => _.isEmpty(value));
      setDisable(isEmpty);

      // Focus the next input if the current one has a value
      if (!newOtp[index] && index > 0) {
        inputFieldsRef.current[index - 1]?.focus();
      } else if (value && index < inputFieldsRef.current.length - 1) {
        inputFieldsRef.current[index + 1]?.focus();
      }
    }
  };
  const handleSubmit = () => {
    setLoading(true);
    setIncorrectPassword(false);
    const enteredOtp = otpValue.join("");
    // Simulate OTP validation process
    const url = validateOTP;
    // data.number = Number(data.number);
    console.log({ email });
    const decodedEmail = decodeBase64(String(email));
    const data = { email: decodedEmail, otpCode: enteredOtp };
    console.log("data", data);
    axios
      .post(url, data)
      .then((res: any) => {
        setLoading(false);

        if (res.data.success) {
          history.push(`/additionalDetails/${email}`);
        } else {
          setIncorrectPassword(true);
          setErrorMsg("Please enter the correct code.");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err }, err?.response?.data?.message);
        setIncorrectPassword(true);
        setErrorMsg(err?.response?.data?.error ?? err.response.data.message);
      });
  };
  const handleResend = () => {
    console.log("info: Resend Initiated");
    setSeconds(resend_OTP_Ms);
    disableResendTimer();
    setIncorrectPassword(false);
    const decodedEmail = decodeBase64(String(email));
    console.log(resendOTP);
    axios
      .post(resendOTP, { email: decodedEmail })
      .then((res: any) => {
        console.log("info: OTPResend", res);
        if (res.data.success) {
          <Message message="OTP resend success" />;
        }
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
        <Message message="OTP resend failed" />;
      });
  };
  const handleEditDetails = () => {
    console.log("HandelEditDetails");
    history.push(`/signUp/freeTrail/${email}`);
  };
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };
  const disableResendTimer = () => {
    setDisableResend(true);
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = window.setInterval(() => {
      setSeconds((prev) => {
        if (prev > 0) return prev - 1;
        if (intervalRef.current !== null) {
          clearInterval(intervalRef.current);
        }
        return prev;
      });
      console.log("Interval");
    }, 1000);
  };

  useEffect(() => {
    if (seconds === 0) {
      setDisableResend(false);
    }
  }, [seconds]);

  useEffect(() => {
    setEmailId(decodeBase64(email));
  }, [email]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === "POP") {
        // Prevent the back navigation
        return false;
      }
    });

    return () => {
      unblock();
    };
  }, [history]);

  useEffect(() => {
    if (inputFieldsRef.current[0]) {
      inputFieldsRef.current[0].focus(); // Focus on the first textbox
    }
    disableResendTimer();

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      setDisableResend(false);
    };
  }, []);

  return {
    handleSubmit,
    incorrectPassword,
    handleResend,
    disableResend,
    formatTime,
    disable,
    seconds,
    loading,
    otpValue,
    handleChange,
    inputFieldsRef,
    emailId,
    errorMsg,
    handleEditDetails,
  };
};
