import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  decodeBase64,
  EmailRouteParams,
} from "../../../../../constants/SAASFlowConstants";
import axios from "axios";
import { additionalDetails } from "../../../../../helpers/RestApisHelper/RestApis";

const useAdditionalDetails = () => {
  const history = useHistory();
  const { email } = useParams<EmailRouteParams>();
  const [form] = useForm();
  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: any) => {
    setLoading(true);
    if (!termsAccepted) {
      setErrorMsg("Kindly agree to Terms and Conditions.");
      setLoading(false);
      return;
    }
    const url = additionalDetails;

    const decodedEmail = decodeBase64(email);

    values.email = decodedEmail;
    values.termsAndConditions = termsAccepted;
    values.category = values.industryType.split("-")[1];
    values.industryType = values.industryType.split("-")[0];
    values.enquiries = [
      {
        product: "DDE",
        package: "Trial",
      },
    ];

    console.log(
      "useAdditionalDetails handle Submit",
      termsAccepted,
      decodedEmail,
      url,
      values
    );

    axios
      .post(url, values)
      .then((res: any) => {
        console.log({ res });
        setLoading(false);
        if (res?.status === 200) {
          console.log(res?.data?.success);
          history.push("/submitResponse/FreeTrail");
        } else setErrorMsg(res?.data?.error);
      })
      .catch((err) => {
        console.log("additionalDetailsError", err?.response?.data?.error);
        setErrorMsg(err?.response?.data?.error);
        setLoading(false);
      });
  };
  const handleTermsAgree = () => {
    setTermsAccepted(true);
    setErrorMsg("");
    setShowTermsModal(false);
  };

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === "POP") {
        // Prevent the back navigation
        return false;
      }
    });

    return () => {
      unblock();
    };
  }, [history]);

  return {
    form,
    showTermsModal,
    setTermsAccepted,
    termsAccepted,
    setShowTermsModal,
    errorMsg,
    handleSubmit,
    setErrorMsg,
    handleTermsAgree,
    loading,
  };
};

export default useAdditionalDetails;
