import { Input } from "ae-ui-components";
import { Button, Form } from "antd";
import "./EmailInput.scss";

interface Props {
  // handleEmail(emailValue: string | undefined): void;
  handleEmailSubmit(emailValue: string | undefined): void;
  loading: boolean;
  error: boolean;
  isReset?: boolean;
}
const EmailInput = ({ handleEmailSubmit, loading, isReset, error }: Props) => {
  const handleSetEmail = (vals: any) => {
    // handleEmail(emailValue);
    if (!isReset) {
      const { email } = vals;
      handleEmailSubmit(email);
    }
  };

  return (
    <Form
      labelCol={{ span: 24 }}
      colon={false}
      onFinish={handleSetEmail}
      className="email-input-form"
      requiredMark={false}
    >
      <Form.Item
        rules={[{ required: true, message: "Email ID not recognised." }]}
        name="email"
        label="Enter Email"
        // style={{ marginTop: "120px" }}
        labelCol={{ span: 24, style: { fontWeight: 600 } }}
        validateStatus={error ? "error" : undefined}
        help={
          error
            ? "Please enter your authorized login email or sign up with us to gain access."
            : ""
        }
      >
        <Input placeholder="" style={{ width: "100%", height: "34px" }} />
      </Form.Item>
      <Form.Item
      // style={{ marginTop: "100px" }}
      >
        <Button
          style={{
            width: "294px",
            height: "36px",
          }}
          loading={loading}
          htmlType="submit"
          className="default-button-class"
        >
          {isReset ? "Reset" : "Next"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EmailInput;
