import React, { useEffect, useState } from "react";
import RealmsList from "../../../components/LandingPage/RealmsList/ui/RealmsList";
import { useAuth } from "../../../hooks";
import { getKeycloackRedirectUri } from "../../../helpers";
import { tenantListUrl } from "../../../helpers/RestApisHelper/RestApis";
import { fetchUser } from "../../../components/LandingPage/functions/fetchUser";
import "./RealmsPage.scss";

const RealmsPage = () => {
  const [realmsFromFetch, setRealmFromFetch] =
    useState<{ id: number; name: string }[]>();
  const { getEmail, getCurrentRealm } = useAuth();
  useEffect(() => {
    const email = getEmail();
    const data = JSON.stringify({
      email: email?.trim(),
    });
    const url = tenantListUrl;
    if (email) {
      fetchUser(url, data)
        .then((realms) => {
          setRealmFromFetch(realms.data);
        })
        .catch((error) => {
          if (error) {
            console.log({ error });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRealms = (realmName: string) => {
    const currRealm = getCurrentRealm();
    const redirectUri = getKeycloackRedirectUri(realmName);
    const email = getEmail();
    const redirectUriWithEmail = `${
      currRealm ? redirectUri.replace(`.${currRealm}`, "") : redirectUri
    }/products?email=${email}`;
    console.log({ email, redirectUri, realmName, redirectUriWithEmail });
    window.location.href = redirectUriWithEmail;
  };

  return (
    <div className="tenants-page">
      <RealmsList
        realmsFromFetch={realmsFromFetch}
        handleRealm={handleRealms}
      />
    </div>
  );
};

export default RealmsPage;
