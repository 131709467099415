import React, { useEffect } from "react";
import { ANY } from "../../types";

export const useOnClickOutside = (
  ref: React.RefObject<ANY>,
  handler: (event: ANY) => void
) => {
  useEffect(() => {
    const listener = (event: ANY) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
