import { AeLogoSmall } from "../../../assets";
import getRedirectUri from "../../../helpers/getRedirectUrl";
import { useAuth } from "../../../hooks";
import { LoadingSpinner } from "../../../components/common";
import { ReportCard } from "../../../components/ReportCard";
import useReportsPage from "../hooks/useReportsPage";
import { useHistory } from "react-router-dom";
import { Button, Input, Select } from "antd";
import "./ReportPage.scss";
import { Search } from "react-feather";
import { Typography } from "ae-ui-components";
import "./ReportPage.scss";
import _ from "lodash";

const ReportsPage = () => {
  const { logout, getName } = useAuth();
  const history = useHistory();

  const {
    loading,
    reportsData,
    productOptions,
    onChangeProduct,
    onChangeSearch,
  } = useReportsPage();
  const { Caption } = Typography;
  console.log({
    redirectUri: getRedirectUri(),
    reportsData,
  });
  return (
    <div style={{ overflowX: "hidden" }} className="products-list-container">
      <div className="products-list-inner-container">
        <div className="products-list-container-header">
          <div>
            <AeLogoSmall />
          </div>
          <div className="flex">
            <div style={{ marginRight: "10px" }}>
              <Button
                className="default-button-class"
                style={{ width: "90px", height: "36px" }}
                onClick={() => history.push("/products")}
              >
                Back
              </Button>
            </div>
            <div className="mr-2">
              {getRedirectUri ? (
                <Button
                  className="default-button-class"
                  style={{ width: "90px", height: "36px" }}
                  onClick={() =>
                    logout({
                      redirectUri: getRedirectUri(),
                    })
                  }
                >
                  Logout
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="reports-list-container-text">
          <div className="products-list-container-text">
            <div>
              <p>Welcome{!!getName ? `, ${_.startCase(getName())}` : ""}</p>
            </div>
            <div>
              <p>
                Select a portfolio or create a new portfolio to get started.
              </p>
            </div>
          </div>
          <div className="reports-list-container-filter">
            <div
              className="reports-list-container-filter-options"
              style={{ paddingRight: "10px" }}
            >
              <Caption bold> Search: </Caption>
              <Input
                onChange={(e) => onChangeSearch(e.target.value)}
                prefix={<Search size={12} />}
                style={{ width: "200px" }}
              />
            </div>
            <div className="reports-list-container-filter-options">
              <Caption bold>Filter: </Caption>
              <Select
                onChange={onChangeProduct}
                defaultValue={"all"}
                options={productOptions}
                style={{ width: "200px" }}
              />
            </div>
          </div>
        </div>

        <div className="products-list-container-tenant-list-container">
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingSpinner spinning />
            </div>
          ) : reportsData.length ? (
            reportsData?.map((eachReport) => {
              return (
                <ReportCard
                  id={eachReport.reportId}
                  clickId={eachReport.id}
                  reportName={eachReport.reportName}
                  desc={eachReport.desc}
                  url={eachReport.imgUrl}
                  product={eachReport.product}
                  createdOn={eachReport.createdAt}
                />
              );
            })
          ) : (
            <p className="reports-empty">No reports to show currently.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;
