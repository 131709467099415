export const DEMAND_DRIVER_PRODUCT_KEY = "demand-drivers";
export const PRICESENSE_PRODUCT_KEY = "pricesense";
export const SYNTEST_PRODUCT_KEY = "syntest";
export const CUSTOM_REPORTS_PRODUCT_KEY: string = "custom-reports";

const descriptionObj = {
  [DEMAND_DRIVER_PRODUCT_KEY]:
    "Leverage real-time insights to guide marketing planning decisions",
  [PRICESENSE_PRODUCT_KEY]:
    "Leverage pricing and promotions to maximize growth",
  [SYNTEST_PRODUCT_KEY]:
    "Maximize sales and margins with the optimal assortment of SKUs",
  [CUSTOM_REPORTS_PRODUCT_KEY]: "text for custom",
};

export default descriptionObj;
