import { useState } from "react";
import EmailInput from "../EmailInput/ui/EmailInput";
import RealmsList from "../RealmsList/ui/RealmsList";
import { tenantListUrl } from "../../../helpers/RestApisHelper/RestApis";
import { fetchUser } from "../functions/fetchUser";
import "./LandingPage.scss";
import AeLogo from "../../../assets/imgs/AE_Qube_color_rgb.png";
import { Link, useLocation } from "react-router-dom";
import { NormalRoutes } from "../../Routes/normalRoutes";

interface Props {
  handleRealm(name: string, email: string): void;
  setIsEmail: (data: string) => void;
}

const LandingPage = ({ handleRealm, setIsEmail }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmailFetch, setIsEmailFetch] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [realmsFromFetch, setRealmFromFetch] =
    useState<{ id: number; name: string }[]>();
  const [error, setError] = useState<boolean>(false);
  const location = useLocation();
  console.log("from landing page", { email });
  const handleEmailSubmit = async (email: string | undefined) => {
    setLoading(true);
    const data = JSON.stringify({
      email: email?.trim(),
    });
    const url = tenantListUrl;
    if (email) {
      await fetchUser(url, data)
        .then((realms) => {
          if (realms.data.length) {
            setRealmFromFetch(realms.data);
            setIsEmail(email);
            setIsEmailFetch(true);
            setLoading(false);
            setError(false);
            setEmail(email?.trim());
          } else {
            setEmail(null);

            setError(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log({ error });
          if (error) {
            setEmail(null);

            setError(true);
            setLoading(false);
          }
        });
    }
    setLoading(false);
  };
  console.log({ loc: location.pathname });
  return (
    <>
      <div className="landing-page">
        {!(isEmailFetch && realmsFromFetch) ? (
          <div>
            <div className="email-card">
              {location.pathname === "/" ? (
                <>
                  <div className="email-input-header">
                    <img src={AeLogo} alt="ae-qube" />
                  </div>
                  <EmailInput
                    // handleEmail={handleEmail}
                    loading={loading}
                    handleEmailSubmit={handleEmailSubmit}
                    error={error}
                  />
                  <div className="login-footer">
                    <p>
                      Don’t have an account with us yet?{" "}
                      <Link to="/signUp">Sign up here</Link>
                    </p>
                  </div>{" "}
                </>
              ) : (
                <NormalRoutes />
              )}
            </div>
            {location.pathname !== "/" && (
              <div className="support-label-container">
                <b>
                  For assistance, contact us at{" "}
                  <span>support@analytic-edge.com</span>{" "}
                </b>
              </div>
            )}
          </div>
        ) : (
          <RealmsList
            handleRealm={(name: string) => handleRealm(name, email || "")}
            setIsEmailFetch={setIsEmailFetch}
            realmsFromFetch={realmsFromFetch}
          />
        )}
      </div>
    </>
  );
};

export default LandingPage;
