import { Input, Typography } from "ae-ui-components";
import { Button, Form } from "antd";
import "./SignUp.scss";
import { useHistory } from "react-router-dom";
import { ArrowLeft, CheckCircle } from "react-feather";
import useSignUp from "../hooks/useSignUp";

const { Title } = Typography;

const SignUp = () => {
  const {
    form,
    handleSignUp,
    yupSyncEmail,
    yupSyncPhoneNumber,
    success,
    loading,
    error,
  } = useSignUp();
  const history = useHistory();
  return (
    <div className="sign-up-container">
      {success ? (
        <div className="sign-up-container success">
          <Title className="flex items-center" level={3}>
            Your email has been sent, our team will contact you shortly.
          </Title>
          <div style={{ marginBottom: "20px" }}>
            <CheckCircle size={50} color="#34C759" />
          </div>
          <div>
            <ArrowLeft
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
              size={35}
              color="#005ABC"
            />
          </div>
        </div>
      ) : (
        <>
          {" "}
          <Title
            className="flex items-center sign-up-container-title"
            level={3}
          >
            <ArrowLeft
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/")}
            />{" "}
            Sign Up
          </Title>
          <Form
            className="sign-up-container-form"
            form={form}
            onFinish={handleSignUp}
          >
            <div className="sign-up-container-form-inner">
              <Form.Item
                rules={[{ required: true, message: "Enter a value." }]}
                name="firstName"
                label="First Name"
                labelCol={{ span: 24, style: { fontWeight: 600 } }}
              >
                <Input
                  placeholder=""
                  style={{ width: "100%", height: "34px" }}
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Enter a value." }]}
                name="lastName"
                label="Last Name"
                labelCol={{ span: 24, style: { fontWeight: 600 } }}
              >
                <Input
                  placeholder=""
                  style={{ width: "100%", height: "34px" }}
                />
              </Form.Item>
              <Form.Item
                rules={[yupSyncEmail as any]}
                name="email"
                label="Email"
                required
                labelCol={{ span: 24, style: { fontWeight: 600 } }}
              >
                <Input
                  placeholder=""
                  style={{ width: "100%", height: "34px" }}
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Enter a value." }]}
                name="company"
                label="Company"
                labelCol={{ span: 24, style: { fontWeight: 600 } }}
              >
                <Input
                  placeholder=""
                  style={{ width: "100%", height: "34px" }}
                />
              </Form.Item>
              <Form.Item
                rules={[yupSyncPhoneNumber as any]}
                name="number"
                label="Number"
                required
                labelCol={{ span: 24, style: { fontWeight: 600 } }}
              >
                <Input
                  placeholder=""
                  style={{ width: "100%", height: "34px" }}
                />
              </Form.Item>
            </div>
            <Form.Item
              hasFeedback
              className="sign-up-container-signup"
              validateStatus={error ? "error" : undefined}
              help={error ? "Please try again." : undefined}
            >
              <Button
                loading={loading}
                // onClick={() => history.push("/")}
                style={{ width: "100%" }}
                htmlType="submit"
                className="default-button-class"
              >
                Sign Up
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};

export default SignUp;
