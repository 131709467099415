import React from "react";
import { Route, Switch } from "react-router-dom";
import { SignUp } from "../../../LandingPage/SignUp";
import { ForgotPassword } from "../../../ForgotPassword";
import { ConfirmPassword } from "../../../ConfirmPassword";
import { SaasSignUp } from "../../../SAASFlow/TrailUserSignUp/SignUp/FreeTrail";
import LandingPage from "../../../LandingPage/ui/LandingPage";
import { OTPValidation } from "../../../SAASFlow/OTPValidation";
import { AdditionalDetails } from "../../../SAASFlow/TrailUserSignUp/AdditionalDetails";
import { SubmitResponse } from "../../../SAASFlow/SubmitResponse";

const NormalRoutes = () => {
  return (
    <Switch>
      <Route exact path={["/"]} component={LandingPage} />
      <Route exact path={["/signUp"]} component={SignUp} />
      <Route exact path={["/SignUp/FreeTrail"]} component={SaasSignUp} />
      <Route exact path={["/SignUp/FreeTrail/:id"]} component={SaasSignUp} />
      <Route exact path={["/validation/:email"]} component={OTPValidation} />
      <Route
        exact
        path={["/additionalDetails/:email"]}
        component={AdditionalDetails}
      />
      <Route
        exact
        path={["/submitResponse/:subscription"]}
        component={SubmitResponse}
      />
      <Route exact path={["/forgotPassword"]} component={ForgotPassword} />
      <Route
        exact
        path="/confirmPassword/:userId/:token"
        component={ConfirmPassword}
      />
    </Switch>
  );
};

export default NormalRoutes;
