import React, { useState } from "react";
import "./ProjectCard.scss";
import { Button } from "antd";
import { ProductType } from "../../../pages/projects/ui/ProductsList";
import { getKeycloackRedirectUri, getRealmFromUrl } from "../../../helpers";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "ae-ui-components";
import { ProjectCardColors } from "../../../helpers/Project page helpers/projectCardColors";

interface Props {
  eachProduct: ProductType;
  isUnAvailable: boolean;
}

const ProjectCard = ({ eachProduct, isUnAvailable }: Props) => {
  // const handleLearnMore = (link: string) => {
  //   window.location.href = link;
  // };
  const history = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleProductClick = (
    url: string,
    shouldNavigate: boolean,
    realm: string | null
  ) => {
    if (shouldNavigate && realm) {
      const redirectUri = getKeycloackRedirectUri(realm, url, true);
      window.location.href = redirectUri;
    }
  };
  const handleCustom = () => {
    console.log("Called reports");
    history.push("/reports");
  };
  const handleGetAccess = () => {
    setOpenModal(true);
  };

  console.log({ condition: eachProduct.id.includes("custom"), eachProduct });
  return (
    <>
      <Modal
        shouldCloseOnOverlayClick
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        size="small"
      >
        Your request has been noted.
      </Modal>
      <div
        role="button"
        className={`product-card ${
          isUnAvailable ? "isUnAvailable" : ""
        } grid-item-product-card`}
        style={{
          borderBottomColor:
            ProjectCardColors[eachProduct.productId].primaryColor,
        }}
        onClick={() =>
          eachProduct.id.includes("custom")
            ? handleCustom()
            : handleProductClick(
                eachProduct.baseUrl,
                isUnAvailable === false,
                getRealmFromUrl()
              )
        }
        onKeyDown={() =>
          eachProduct.id.includes("custom")
            ? handleCustom
            : handleProductClick(
                eachProduct.baseUrl,
                isUnAvailable === false,
                getRealmFromUrl()
              )
        }
      >
        <div className="product-card-name">
          {ProjectCardColors[eachProduct.productId].fullNameWithCube}
        </div>
        <div className="product-card-description">
          <p>{eachProduct.desc || ""}</p>
        </div>
        <div className="product-card-learn-more">
          <Link to={{ pathname: eachProduct.learnMore }} target="_blank">
            <Button
              className="link-button-bold learn-more-box-button"
              type="link"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Learn More
            </Button>
          </Link>
          {/* <Link to={{ pathname: eachProduct.learnMore }} target="_blank">
            <span>
              <Info />
            </span>{" "}
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            ></span>
          </Link> */}
          {isUnAvailable ? (
            <div className="product-card-get-access">
              <div>
                <Button
                  className="link-button-bold learn-more-box-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleGetAccess();
                  }}
                  type="link"
                >
                  Get Access
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
