import { Input, Typography } from "ae-ui-components";
import { Button, Form } from "antd";
import "./SignUp.scss";
import { Link } from "react-router-dom";
import useSignUp from "../hooks/useSignUp";

const { Title } = Typography;

const SaasSignUp = () => {
  const { form, handleSignUp, yupSyncEmail, loading, validationMsg, error } =
    useSignUp();

  return (
    <div className="trail-sign-up-container">
      <>
        <Title className="flex items-center sign-up-container-title" level={3}>
          Unlock 5-day Free Trial
        </Title>
        <Form
          className="sign-up-container-form"
          form={form}
          onFinish={handleSignUp}
        >
          <div className="sign-up-container-form-inner">
            <Form.Item
              rules={[{ required: true, message: "This field is required." }]}
              name="firstName"
              label="First Name"
              labelCol={{ span: 24, style: { fontWeight: 600 } }}
            >
              <Input
                placeholder="Enter your First Name"
                className="form-text-box"
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "This field is required." }]}
              name="lastName"
              label="Last Name"
              labelCol={{ span: 24, style: { fontWeight: 600 } }}
              validateStatus="success"
            >
              <Input
                placeholder="Enter your Last Name"
                className="form-text-box"
              />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true, message: "This field is required." },
                yupSyncEmail as any,
              ]}
              name="email"
              label="Work Email"
              required
              labelCol={{ span: 24, style: { fontWeight: 600 } }}
            >
              <Input
                placeholder="Enter your Work Email"
                className="form-text-box"
              />
            </Form.Item>
            <Form.Item
              hasFeedback
              className="pt-12"
              validateStatus={error ? "error" : undefined}
              help={error ? validationMsg : undefined}
            >
              <Button
                loading={loading}
                htmlType="submit"
                className="default-button-class w-100"
              >
                Next
              </Button>
            </Form.Item>
            <Form.Item>
              Already a registered user?{" "}
              <Link to="/" className="link-color">
                Sign In here
              </Link>
            </Form.Item>
          </div>
          <Form.Item
            label="To utilize the full potential of the platform"
            labelCol={{ span: 24, style: { fontWeight: 500 } }}
          ></Form.Item>
          <Form.Item hasFeedback className="sign-up-container-signup">
            <Button type="primary" className="w-100 btn-radius-4" disabled>
              Subscribe to Tier
            </Button>
          </Form.Item>
        </Form>
      </>
    </div>
  );
};

export default SaasSignUp;
